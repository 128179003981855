/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {

        setTimeout(function () {
          $('body').css('zoom', '0.8');
          $('body').css('background', 'none');
          $('div.slider, .general').css('visibility', 'visible');
          $('div.slider, .general').css('opacity', '1');

          var $counterio = 1;
          $('.nextend-bullet-bar.n2-ow.n2-bar-justify-content-center div.n2-ow').each(function (index) {
            $(this).append('<span>0' + $counterio + '</span>');
            $counterio++;
          });

        }, 100);


        jQuery("input.mailpoet_text").attr("placeholder", "Ingresar mail para subscribirse");

        //scroll
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });


        $(".menu-bars").on("click", function () {

          $("nav ul").toggleClass("showing");

        });


        $("nav ul li").on("click", function () {

          $("nav ul").removeClass("showing");

        });


        var contadorDestacados = 0;

        $('button#destacadosAvanzar').click(function () {


          if (contadorDestacados < arrayDestacados.length - 1) {
            contadorDestacados++;
          } else {
            contadorDestacados = 0;
          }

          $.ajax({
            type: "GET",
            url: api_url + 'productos-destacados-home',
            data: {
              'id': arrayDestacados[contadorDestacados],
            },
            success: function (msg) {
              //remplazo la imagen
              $('#resultados').html(JSON.stringify(msg.imagen));
              $(".imagen-producto-destacado img").attr("src", msg.imagen);
              //remplazo los principios activos
              //var content = '<ul>';
              //   $.each(msg.activo, function (){
              //     content += '<li>' + this.name + '</li>';
              //   });
              //   content += '</ul>' ;
              //
              //   $('.descripcion-producto-destacado').html(content);
              //remplazo el titulo y la especialidad
              //$('.descripcion-producto-destacado span.info').html(JSON.stringify(msg.especialidad).replace(/['"]+/g, ''))
              var content = '' +
                '<div class="logo col-4"></div>' +
                '<div class="nombre col-8">' +
                '<span class="titulo">' + JSON.stringify(msg.title).replace(/['"]+/g, '') + '&reg;' + '</span> ' +
                '<span class="especialidad">' + JSON.stringify(msg.especialidad).replace(/['"]+/g, '') + '</span>' +
                '</div>';
              $('.descripcion-producto-destacado').html(content);
              /*for(var i = 0; i < msg.activo.length; i++){
                $(".descripcion-produccto-destacado").append("<span> + 'msg.activo[i].name' + </span>");
                console.log(msg.activo[i].name);
              }*/
            },
            error: function (msg) {
              console.log(msg.statusText);
            }
          });


        });

        $('button#destacadosRetroceder').click(function () {


          if (contadorDestacados > arrayDestacados.length - 1) {
            contadorDestacados--;
          } else {
            contadorDestacados = 0;
          }

          $.ajax({
            type: "GET",
            url: api_url + 'productos-destacados-home',
            data: {
              'id': arrayDestacados[contadorDestacados],
            },
            success: function (msg) {

              //remplazo la imagen
              $('#resultados').html(JSON.stringify(msg.imagen));
              $(".imagen-producto-destacado img").attr("src", msg.imagen);
              //remplazo los principios activos
              //var content = '<ul>';
              //   $.each(msg.activo, function (){
              //     content += '<li>' + this.name + '</li>';
              //   });
              //   content += '</ul>' ;
              //
              //   $('.descripcion-producto-destacado').html(content);
              //remplazo el titulo y la especialidad
              //$('.descripcion-producto-destacado span.info').html(JSON.stringify(msg.especialidad).replace(/['"]+/g, ''))
              var content = '' +
                '<div class="logo col-4"></div>' +
                '<div class="nombre col-8">' +
                '<span class="titulo">' + JSON.stringify(msg.title).replace(/['"]+/g, '') + '&reg;' + '</span> ' +
                '<span class="especialidad">' + JSON.stringify(msg.especialidad).replace(/['"]+/g, '') + '</span>' +
                '</div>';
              $('.descripcion-producto-destacado').html(content);
              /*for(var i = 0; i < msg.activo.length; i++){
                $(".descripcion-produccto-destacado").append("<span> + 'msg.activo[i].name' + </span>");
                console.log(msg.activo[i].name);
              }*/
            },
            error: function (msg) {
              console.log(msg.statusText);
            }
          });

        });


        if (typeof arrayImagenesMaterialDeLectura !== null) {
          $('.galeria-imagenes-material-de-lectura li').click(function () {
            var imagen = $(this).data('key');
            var content = '' +
              '<a class="replacecontentgallery" href="' + arrayImagenesMaterialDeLectura[imagen] + '" data-toggle="lightbox" style="position: relative; z-index: 5"/>'+'</a>';
            $('.replacecontentgallery').replaceWith(content);

            var appendImg = '' + '<img class="imagen imagen-material-de-lectura" src="' + arrayImagenesMaterialDeLectura[imagen] + '" alt="imagen del material de lectura"/>';
            $('.replacecontentgallery').append(appendImg);


            var idModalHref = '' +
              '<a class="idModalHref" style="width: 100%; height: 100%; align-items: center; display: flex; justify-content: center;" href="#' + imagen + '" >' +
              '<div class="imagen-lupa" style="background-image: url(http://php71.projectsunderdev.com/raffo/web/app/uploads/2019/01/lupa.png)"><span>AMPLIAR IMAGEN</span></div>' +
              '</a>';

            $('.idModalHref').replaceWith(idModalHref);

            // <div class="imagen-lupa" style="background-image: url('http://php71.projectsunderdev.com/raffo/web/app/uploads/2019/01/lupa.png')"></div>

            $('.galeria-imagenes-material-de-lectura li span').removeClass('relleno');
            $(this).find('span').addClass('relleno');
            var contentModal = '' +
              '<div class="prueba">' +
              '<div class="modal" id="' + imagen + '">' +
              '<div class="imagen" style="margin-top:10%;">' +
              '<img src="' + arrayImagenesMaterialDeLectura[imagen] + '"/>' +
              '</div>' +
              '<a class="cerrar" href="#product-content">X</a>' +
              '</div>' +
              '</div>';
            $('.prueba').replaceWith(contentModal);
          });
        }


        // searchFromSingleProduct
        //   ?letra=&especialidad=&principio-activo=

        $('#searchFromSingleProduct').click(function () {
          var letraFormSearchSingleProduct = $("input[name='letra']").val();
          var EspecialidadFormSearchSingleProduct = $("select[name='especialidad']").val();
          var PrincipioActivoFormSearchSingleProduct = $("select[name='principio-activo']").val();
          var origin = window.location.origin;
          window.location.replace(origin + '/productos/?letra=' + letraFormSearchSingleProduct + '&especialidad=' + EspecialidadFormSearchSingleProduct + '&principio-activo=' + PrincipioActivoFormSearchSingleProduct);
        });


        // JavaScript to be fired on all pages

        var pathname = window.location.href;
        var p = pathname.search('listabc');

        if (p > 0) {
          if (pathname[p + 9]) {
            $('#product_list #abc a:nth-child(' + pathname[p + 8] + pathname[p + 9] + ')').addClass('active');
          } else {
            $('#product_list #abc a:nth-child(' + pathname[p + 8] + ')').addClass('active');
          }
        }





      },
      finalize: function () {
        jQuery('.n2-ss-slide-background-image').css('background-size', 'cover');
        jQuery('.n2-ss-slide-background-image').css('background-position-y', '26%');


        // $('#product_list #abc a').click(function(e){
        //   $(this).addClass('active');
        // });
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {

        $('.slick-productos-destacados').slick({
          prevArrow: false,
          nextArrow: false,
          draggable: false
        });

        var divs_rellenar = $('.grafico').children().length;

        $('.grafico div:first-child').addClass('relleno');


        setInterval(function () {

        }, 500);


        $('#destacadosRetroceder').click(function () {
          $('.grafico div.relleno:last').removeClass('relleno');
          $('.slick-productos-destacados').slick("slickPrev");

          var auxPrev = $('.slick-current').attr('data-slick-index');
          auxPrev = parseInt(auxPrev);
          auxPrev += 1;
          $('.grafico div.relleno').removeClass('relleno');
          if (auxPrev == 0) {
            $('.grafico div:nth-child(' + parseInt($('.grafico').children().length) + ')').addClass('relleno');
          } else {
            for (var o = 0; o <= auxPrev; o++) {
              $('.grafico div:nth-child(' + o + ')').addClass('relleno');
            }
          }
        });


        $('#destacadosAvanzar').click(function () {
          $('.slick-productos-destacados').slick("slickNext");
          var auxNext = $('.slick-current').attr('data-slick-index');
          auxNext = parseInt(auxNext);
          auxNext += 1;
          $('.grafico div.relleno').removeClass('relleno');
          if (auxNext > parseInt($('.grafico').children().length)) {
            $('.grafico div:nth-child(' + auxNext + ')').addClass('relleno');
          } else {
            for (var i = 0; i <= auxNext; i++) {
              $('.grafico div:nth-child(' + i + ')').addClass('relleno');
            }
          }
        });


        // var hijos = $('div.n2-ss-slide-background').children().length;
        // for (var i = 1; i <= hijos; i++) {
        //   $('#recuadroBlanco').append('<span>' + i + '</span>');
        // }

        // JavaScript to be fired on the home page
      },
      finalize: function () {

        //JAVASCRIPT SLIDER, NUMEROS DE IMAGENES

        var hijos = $('div.n2-ss-slide-background').children().length;
        for (var i = 1; i <= hijos; i++) {
          $('#recuadroBlanco').append('<span data-position="' + i + '">0' + i + '</span>');

          // $("#recuadroBlanco span").click(function () {
          //   $("#recuadroBlanco span").css('color', 'black');
          //   $(this).css('color', 'white');
          //   var position = $(this).data('position');
          //   $('.nextend-bullet-bar .n2-ow:nth-child(' + position + ')').click();
          // });
        }
        setInterval(function () {
          $('.nextend-bullet-bar div.n2-ow').each(function (index, element) {

            if ($(this).hasClass('n2-active')) {
              $("#recuadroBlanco span").css('color', 'black');
              $("#recuadroBlanco span:nth-child(" + (index + 1) + ")").css('color', 'white');
            }

          });
        }, 300);


        $('.n2-ss-layers-container .n2-ss-layer .n2-ow').addClass('container');
        $('.n2-ss-layers-container .n2-ss-layer .n2-ow .n2-ss-item-content:nth-child(1)').css('padding-left', '0');
        $('.n2-ss-control-bullet-horizontal').addClass('container');
        $('.n2-ow .text-top .n2-ss-tablet ').css('padding-left', '0');


        var transparentFix = '<div class="transparentFixHero"></div>';
        jQuery('.n2-ss-slider-3').append(transparentFix)

        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    },


    'info_cientifica': {
      init: function () {


        $('#search-material-lectura').change(function () {
          $.ajax({
            type: "GET",
            url: api_url + 'materiales-de-lectura',
            data: {
              'cantidadMaterial': arrayMateriales.post_count,
              'especialidad': $(this).val()//mando la especialidad
            },
            success: function (msg) {
              var content = msg.data;
              $('#materiales-ajax').html(content);
            },
            error: function (msg) {
              console.log(msg.statusText);
            }
          });
        });


        $('#search-eventos').change(function () {
          if ($(this).val() == 1) {
            $.ajax({
              type: "GET",
              url: api_url + 'eventos-info-cientifica',
              data: {
                'cantidadEventos': arrayEventos.post_count,
                'order': 'DESC'//mas antiguos
              },
              success: function (msg) {
                var content = msg.stringxd;
                $('#eventos-ajax').html(content);
              },
              error: function (msg) {
                console.log(msg.statusText);
              }
            });
          } else {
            $.ajax({
              type: "GET",
              url: api_url + 'eventos-info-cientifica',
              data: {
                'cantidadEventos': arrayEventos.post_count,
                'order': 'ASC'//mas antiguos
              },
              success: function (msg) {
                var content = msg.stringxd;
                $('#eventos-ajax').html(content);
              },
              error: function (msg) {
                console.log(msg.statusText);
              }
            });
          }


        });


      }

    },


    'listado_material_de_lectura': {
      init: function () {


        $('#search-material-lectura').change(function () {
          $.ajax({
            type: "GET",
            url: api_url + 'materiales-de-lectura',
            data: {
              'cantidadMaterial': arrayMateriales.post_count,
              'especialidad': $(this).val()//mando la especialidad
            },
            success: function (msg) {

              var content = msg.data;
              $('#materiales-ajax').html(content);

            },
            error: function (msg) {
              console.log(msg.statusText);
            }
          });
        });

      }

    },


    'productos': {
      init: function () {

      }
    },


  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


